import React from 'react';
import EmptyComponent from '../EmptyComponent';

import {
    LinearProgress, List, ListItem, ListItemButton, Chip
} from "@mui/material";
import { getOrderStatusColor } from '../../values/helper_functions';
import { collection, getDocs } from "firebase/firestore";
import { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core';
import { db } from "../../firebase-config";

export default function OrdersSideList({ 
    collectionName, docId, isHistory, onClick }) {
    const [isLoading, setIsLoading] = useState(false);

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const getOrders = async () => {
            try {
                setIsLoading(true);

                const path = `${collectionName}/${docId}/${isHistory ? "history" : "orders"}`;
                const ordersCollectionRef = collection(db, path);
                const data = await getDocs(ordersCollectionRef);
                setOrders(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                setIsLoading(false);
            } catch (e) {
                console.log(e);
            }
        }

        getOrders();
    }, [docId]);

    if (isLoading) {
        return <LinearProgress className='card' />
    }

    return (
        <div className="card" style={{ padding: "0px" }}>
            <List>
                <ListItem>
                    <span className='text-xl font-bold'>{isHistory ? "Previous Orders" : "Active Orders"}</span>
                </ListItem>
                {orders.length <= 0 ?
                    <EmptyComponent />
                    :
                    orders.slice(0, 3).map((order, index) => {
                        return <div key={order.id}>
                            <ListItemButton
                                alignItems="flex-start"
                                onClick={()=> onClick(order.id)}
                            >
                                <div className='flex flex-col'>
                                    <span className='text-lg'>{order.customerName}</span>
                                    <span className='text-base text-gray-500'>{order.customerPhone}</span>
                                    <Chip
                                        className='w-fit'
                                        label={order.status}
                                        style={{
                                            color: "white",
                                            backgroundColor: getOrderStatusColor(order.status)
                                        }}
                                    />
                                </div>
                            </ListItemButton>
                            {
                                orders.length <= 3 ?
                                    index < (orders.length - 1) && <Divider />
                                    :
                                    index < 3 && <Divider />
                            }
                        </div>
                    })
                }
                {
                    (orders.length > 3) && <ListItemButton alignItems='center'>
                        See All
                    </ListItemButton>
                }
            </List>
        </div>
    )
}
