import { IconButton } from "@mui/material";
import { OpenInNew, Check } from "@mui/icons-material";

export default function UserInfo({ isEditing, addresses, selectedAddress, setSelectedAddress }) {
    return (
        <div className="card">
            <h3 className="text-xl font-bold">Addresses</h3>
            {(addresses ?? []).length === 0 ?
                <span>No adddress has been added</span>
                :
                (addresses ?? []).map((e) =>
                    <div
                        key={e.id}
                        className="flex flex-row justify-between"
                        onClick={isEditing ? () => setSelectedAddress(e.id) : () => { }}
                        style={
                            {
                                border: "1px solid rgba(118, 118, 118, 0.5)",
                                borderRadius: "5px",
                                backgroundColor: `${e.id === selectedAddress ? "#8FDD8F" : "#EFEFEF"}`,
                                marginTop: "5px",
                                paddingLeft: "10px",
                                cursor: isEditing ? "pointer" : ""
                            }
                        }
                    >
                        <div>
                            <strong>{e.title}:</strong> {e.name}
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    var url = "https://maps.google.com/?q=" + e.geometry.location.lat + "," + e.geometry.location.lng;
                                    window.open(url);
                                }}
                            >
                                <OpenInNew />
                            </IconButton>
                        </div>
                        {e.id === selectedAddress ? <Check style={{ marginRight: "10px", marginTop: "10px" }} /> : <></>}
                    </div>
                )
            }
        </div>
    )
}
