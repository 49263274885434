import * as React from 'react';
import EmptyComponent from '../EmptyComponent';

import {
    List, ListItem, ListItemAvatar, Avatar, ListItemButton,
    LinearProgress
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase-config";
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core';


export default function MenuSection({ collectionName, shopId }) {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [menu, setMenu] = useState([]);

    useEffect(() => {
        const getMenu = async () => {
            setIsLoading(true);

            const menuCollectionRef = collection(db, `${collectionName}/${shopId}/menu`);
            const data = await getDocs(menuCollectionRef);
            setMenu(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

            setIsLoading(false);
        }

        getMenu();
    }, [shopId, menu.length]);

    if (isLoading) {
        return <LinearProgress className='card' />
    }

    return (
        <div className="card" style={{ padding: "0px" }}>
            <List>
                <ListItem>
                    <span className='text-xl font-bold'>Menu</span>
                </ListItem>
                {
                    menu.length <= 0 ?
                        <EmptyComponent />
                        :
                        menu.map((menuItem, index) => {
                            return <div key={menuItem.id}>
                                <ListItemButton
                                    alignItems="flex-start"
                                    onClick={() => navigate(`menu_detail/${menuItem.id}`)}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt="Menu Item" src={menuItem.imagePath} />
                                    </ListItemAvatar>
                                    <div className='flex flex-col'>
                                        <span className='text-lg'>{menuItem.name}</span>
                                        <span className='text-base text-gray-500'>
                                            starting from £{menuItem.price.toFixed(2)}
                                        </span>
                                    </div>
                                </ListItemButton>
                                {
                                    index < (menu.length - 1) && <Divider />
                                }
                            </div>
                        })
                }
            </List>
        </div>
    )
}
