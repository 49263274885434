import EditableTextField from "../EditableTextField";

import { Chip } from "@mui/material";
import { getStatusColor } from "../../values/helper_functions";

export default function UserHeader({
    user, userId, name, email, phoneNumber, photoUrl,
    setVariables, isVerified, isEditing, setIsEditing,

}) {
    return (
        <div className="card">
            <div className="flex flex-row items-center mb-5">
                <img style={{ marginRight: "10px", height: "70px", width: "70px", borderRadius: "50%" }}
                    src={photoUrl ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt='profile' />
                <h1>{name}</h1>
                <Chip
                    label={isVerified ? 'Verified' : 'Not Verified'}
                    style={{
                        backgroundColor: getStatusColor(isVerified ? "verified" : "not_verified"),
                        color: "white",
                        fontSize: "14px",
                        marginLeft: "10px",
                    }}
                />
            </div>
            <div className="flex flex-row items-center">
                <EditableTextField
                    label="Id"
                    value={userId}
                    isEditing={false}
                />
                <EditableTextField
                    label="Email"
                    value={email}
                    isEditing={isEditing}
                />
                <EditableTextField
                    label="Phone"
                    value={phoneNumber}
                    isEditing={isEditing}
                />
            </div>
        </div>
    )
}
