import EditableTextField from '../EditableTextField';

import { getDate } from "../../values/helper_functions";
import { Grid} from "@mui/material";

export default function ItemInfo({
    barcode, dateAdded, lastUpdate, userId, userName
}) {

    return (
        <div className="card">
            <h3 className='text-xl font-bold mb-3'>Item Detail</h3>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Barcode"
                        value={barcode}
                        isEditing={false}
                    />
                </Grid>
                <Grid item xs={6}>                    
                    <EditableTextField
                        label="Date added"
                        value={getDate(dateAdded)}
                        isEditing={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Last updated"
                        value={getDate(lastUpdate)}
                        isEditing={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="User Id"
                        value={userId}
                        isEditing={false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="User Name"
                        value={userName}
                        isEditing={false}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
