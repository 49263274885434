import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion'

import Dashboard from './pages/Dashboard';
import ShopsList from "./pages/ShopsList";
import ShopDetail from "./pages/ShopDetail";
import MenuDetail from "./pages/MenuDetail";
import OrderDetail from "./pages/OrderDetail"
import ScannedItems from "./pages/ScannedItemsList";
import ScannedItemDetail from "./pages/ScannedItemDetail";
import UsersList from "./pages/UsersList";
import UserDetail from "./pages/UserDetail";
import AdminsList from "./pages/AdminsList";
import AdminDetail from "./pages/AdminDetail";
import PromotionsList from "./pages/PromotionsList";
import PromotionDetail from "./pages/PromotionDetail";
import NotFound from "./pages/NotFound";


function AminatedRoutes() {
    const location = useLocation();

    function PrivateRoute({ component: Component }) {
        return <Component />
    }

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                {/* Dashboard*/}

                <Route exact path='/' element={<Navigate to="/dashboard" replace />} />
                <Route exact path='dashboard' element={<PrivateRoute component={Dashboard} />} />

                {/* Shops */}

                <Route exact path='shops/:collectionName' element={<PrivateRoute component={ShopsList} />} />
                <Route
                    exact
                    path='shops/:collectionName/shop_detail/:id'
                    element={<PrivateRoute component={ShopDetail} />}
                />
                <Route
                    exact
                    path='shops/:collectionName/shop_detail/:sid/menu_detail/:mid'
                    element={<PrivateRoute component={MenuDetail} />}
                />
                <Route
                    exact
                    path='shops/:collectionName/shop_detail/:id/order_detail/:isHistory/:oid'
                    element={<PrivateRoute component={OrderDetail} />}
                />

                {/* Users */}

                <Route exact path='users' element={<PrivateRoute component={UsersList} />} />
                <Route exact path='users/user_detail/:id' element={<PrivateRoute component={UserDetail} />} />
                <Route
                    exact
                    path='users/user_detail/:id/order_detail/:collectionName/:isHistory/:oid'
                    element={<PrivateRoute component={OrderDetail} />}
                />

                <Route exact path='admins' element={<PrivateRoute component={AdminsList} />} />
                <Route exact path='admins/admin_detail/:id' element={<PrivateRoute component={AdminDetail} />} />

                {/* Scanned Items */}

                <Route exact path='scanned_items' element={<PrivateRoute component={ScannedItems} />} />
                <Route exact path='scanned_items/scanned_item_detail/:barcode' element={<PrivateRoute component={ScannedItemDetail} />} />

                {/* Promotions */}

                <Route exact path='promotions' element={<PrivateRoute component={PromotionsList} />} />
                <Route exact path='promotions/promotion_detail/:code' element={<PrivateRoute component={PromotionDetail} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AminatedRoutes