import React from 'react';

const Footer = () => (
    <div className='p-4' style={{ backgroundColor: "#0288d1FF" }}>
        <p className="text-white text-center">
            © 2022 All rights reserved by <a
                className='text-gray-900 hover:text-gray-700'
                href="https://ng-solutions.co.uk/"
                target="_blank"
                rel="noreferrer"
            >NG Solutions</a>
        </p>
    </div>
);

export default Footer;