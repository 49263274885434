import EditableTextField from "../EditableTextField";

import { getStatusColor } from "../../values/helper_functions";
import { Chip } from "@mui/material";

export default function UserHeader({ userId, email, phoneNumber,
    photoUrl, name, isVerified, isEditing
}) {
    return (
        <div className="card">
            <div className="flex flex-row items-center mb-5">
                <img style={{ marginRight: "10px", height: "70px", width: "70px", borderRadius: "50%" }}
                    src={photoUrl ?? "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt='profile' />
                <h1 className="text-xl font-bold">{name}</h1>
                <Chip
                    label={isVerified ? 'Verified' : 'Not Verified'}
                    style={{
                        backgroundColor: getStatusColor(isVerified ? "verified" : "not_verified"),
                        color: "white",
                        fontSize: "14px",
                        marginLeft: "10px",
                    }}
                />
            </div>
            <div className="row__start">
                <EditableTextField
                    label="ID"
                    value={userId}
                    isEditing={false}
                />
                <EditableTextField
                    label="Email"
                    value={email}
                    isEditing={isEditing}
                />
                <EditableTextField
                    label="Phone"
                    value={phoneNumber}
                    isEditing={isEditing}
                />
            </div>
        </div>
    )
}
