import * as React from 'react';
import Scaffold from '../components/Scaffold';
import CustomTable from '../components/CustomTable';

import {
  collection, getDoc, onSnapshot, setDoc, doc
} from "firebase/firestore";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config";
import { useState, useEffect, useRef } from 'react';
import { useStateContext } from "../contexts/ContextProvider";
import {
  IconButton, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, TextField, Button,
} from "@mui/material";
import { Add } from "@mui/icons-material";


const columns = [
  {
    title: "Code",
    field: "code",
    width: "200px",

  },
  {
    title: "Message",
    field: "message",
    width: "200px",
  },
  {
    title: "Coupon Type",
    field: "deliveryFeeCoupon",
    width: "200px",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "650px",
  },
  tableContainer: {
    borderRadius: "8px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    letterSpacing: 1,
    backgroundColor: "#42a5f5FF",
    color: theme.palette.getContrastText("#42a5f5FF")
  },
}));

export default function PromotionsList() {
  const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();
  const classes = useStyles();

  const [promocode, setPromocode] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [firestoreData, setFirestoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const snapshot = useRef(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (snapshot.current === undefined) {
        snapshot.current = onSnapshot(
          collection(db, "Promotions"),
          (snapshot) => {
            setFirestoreData([...snapshot.docs.map((doc) => doc.data())]);
            setIsLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
    }

    document.title = "Promotions";
  }, []);

  const addPromotion = async () => {
    try {
      const data = {
        "code": promocode ?? null,
      };
      const docRef = doc(db, `Promotions/${promocode}`);
      if ((await getDoc(docRef)).exists()) {
        setOpenDialog(false);
        setAlertMessage("An item with the same barcode already exists.");
        setAlertType("error")
        setOpenAlert(true);
      } else {
        setOpenDialog(false);
        setIsLoading(true);
        await setDoc(docRef, data);
        setIsLoading(false);
        navigate(`promotion_detail/${promocode}`);
      }
      setPromocode("");
    } catch (e) {
      console.log(e);
      alert("Failed");
    }
  }


  return <Scaffold
    isLoading={isLoading}
  >
    <CustomTable
      columns={columns}
      data={firestoreData}
      classes={classes}
      onClick={(row) => navigate("promotion_detail/" + row.code)}
      searchFields={["code"]}
      titleWidget={
        <IconButton
          onClick={() => setOpenDialog(true)}
        >
          <Add />
        </IconButton>
      }
      styledCell={
        (row, cell) =>
          <span>
            {cell.field === "deliveryFeeCoupon" ?
              (
                row["deliveryFeeCoupon"] ? "Delivery Fee Coupon" : "Discount Coupon"
              )
              : row[cell.field] ?? "NA"}
          </span>
      }
    />

    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Enter Promocode</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter promocode of the new item. Remember this code can't be
          changed after this step.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Promocode"
          fullWidth
          variant="standard"
          value={promocode}
          onChange={event => setPromocode(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          setOpenDialog(false);
          setPromocode("");
        }}>Cancel</Button>
        <Button onClick={() => addPromotion()}>Done</Button>
      </DialogActions>
    </Dialog>
  </Scaffold>;
}
