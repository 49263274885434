import { IconButton, Button, ButtonGroup } from "@mui/material"
import { useState } from 'react';
import { ArrowDownward, Add, RemoveCircle } from "@mui/icons-material"
import DataTable from 'react-data-table-component'

export default function DetailsTable({ variants, setVariants, addons, setAddons,
    fow, setFow, isEditing
}) {
    const [selectedTable, setSelectedTable] = useState("variants");
    const [rows, setRows] = useState(variants);


    const columns = [
        {
            name: "Name",
            selector: (row, index) => {
                if (isEditing && rows[index] === undefined) return <></>;
                return !isEditing ?
                    <span>{row['name']}</span>
                    :
                    <input
                        value={rows[index].name}
                        placeholder="Name"
                        onChange={(event) => {
                            let temp = [...rows];
                            temp[index].name = event.target.value;
                            setRows(temp);
                            handleRowsUpdate(temp);
                        }}
                    />
            }
        },
        {
            name: "Price",
            selector: (row, index) => {
                if (isEditing && rows[index] === undefined) return <></>;

                return !isEditing ?
                    <span>£{row['price'].toFixed(2)}</span>
                    :
                    <input
                        value={rows[index].price}
                        type="number"
                        placeholder="Price"
                        onChange={(event) => {
                            let temp = [...rows];
                            temp[index].price = parseFloat(event.target.value);
                            setRows(temp);
                            handleRowsUpdate(temp);
                        }}
                    />
            },
            sortable: true,
        },

        !isEditing ? {} :
            {
                name: "Delete",
                selector: (row, index) => {
                    if (isEditing && rows[index] === undefined) return <></>;

                    return <IconButton
                        style={{ color: "#F34647FF" }}
                        onClick={() => {
                            let temp = [...rows];
                            temp.splice(index, 1);
                            setRows(temp);
                            handleRowsUpdate(temp);
                        }}
                    >
                        <RemoveCircle />
                    </IconButton>
                },
            },
    ];

    const handleRowsUpdate = newRows => {
        if (selectedTable === "variants") {
            setVariants(newRows);
        } else if (selectedTable === "addOns") {
            setAddons(newRows);
        } else if (selectedTable === "frequentlyOrderedWith") {
            setFow(newRows);
        }
    }

    return (
        <div className="card">
            <DataTable
                title={
                    <div className="flex flex-row items-center">
                        <span className="text-xl font-bold">Details</span>
                        {
                            isEditing &&
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    let temp = [...rows];
                                    temp = [...temp, { name: "", price: 0.0 }]
                                    setRows(temp);
                                    handleRowsUpdate(temp);
                                }}
                            >
                                <Add />
                            </IconButton>
                        }
                    </div>
                }
                columns={columns}
                data={rows}
                defaultSortField="Price"
                sortIcon={<ArrowDownward />}
                pagination
                pointerOnHover
                highlightOnHover
                responsive
                striped
                actions={
                    addons && fow && <ButtonGroup>
                        <Button
                            variant={selectedTable === "variants" ? "contained" : null}
                            color="primary"
                            onClick={() => {
                                setSelectedTable("variants");
                                setRows(variants);
                            }}>
                            Variants
                        </Button>
                        <Button
                            variant={selectedTable === "addOns" ? "contained" : null}
                            color="primary"
                            onClick={() => {
                                setSelectedTable("addOns");
                                setRows(addons);
                            }}>
                            Addons
                        </Button>
                        <Button
                            variant={selectedTable === "frequentlyOrderedWith" ? "contained" : null}
                            color="primary"
                            onClick={() => {
                                setSelectedTable("frequentlyOrderedWith");
                                setRows(fow);
                            }}>
                            Frequently Order With
                        </Button>
                    </ButtonGroup>
                }
            />
        </div>
    )
}
