import { useState, useEffect } from 'react';
import SearchBar from "material-ui-search-bar";

import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, TablePagination
} from '@mui/material';

const CustomTable = ({ columns, data, onClick, styledCell,
    title, titleWidget, showSearchBar = true, searchFields = ["name"], actions
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [rows, setRows] = useState([]);
    const [searched, setSearched] = useState("");

    useEffect(() => {
        requestSearch("");
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const requestSearch = (searchedVal) => {
        const filteredRows = data.filter((row) => {
            let found = false;
            searchFields.map((term) => {
                if ((row[term]??"").toLowerCase().includes(searchedVal.toLowerCase())) {
                    found = true;
                    return;
                }
            })
            return found;
        });
        setRows(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (<div className='p-2'>
        <TableContainer style={{ borderRadius: "8px" }} component={Paper}>
            {
                showSearchBar &&
                <div className='flex flex-row justify-between items-center p-2'>
                    {titleWidget ?? <h3 className='w-80 text-xl font-bold'>{title}</h3>}
                    <div className='flex flex-row justify-end w-full'>
                        <SearchBar
                            className='rounded-lg border-2 border-gray-300 w-4/5 lg:w-5/12'
                            value={searched}
                            onChange={(searchVal) => requestSearch(searchVal)}
                            onCancelSearch={() => cancelSearch()}
                        />
                        {actions}
                    </div>
                </div>
            }
            <Table
                style={{ minWidth: "650px" }}
                stickyHeader
            >
                <TableHead>
                    <TableRow>
                        {
                            columns.map((column) => <TableCell
                                key={column.title}
                                className='border-r-1 border-gray-200'
                                style={{
                                    fontWeight: "bold",
                                    letterSpacing: 1,
                                }}
                            >
                                {column.title}
                            </TableCell>)
                        }
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return <TableRow
                                key={index}
                                hover
                                onClick={() => { onClick(row) }}
                            >
                                {
                                    columns.map((cell) => {
                                        return <TableCell
                                            key={cell.field}
                                        // width={cell.width}
                                        >
                                            {styledCell(row, cell)}
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        })
                    }
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[10, 15, 20]}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    );
}

export default CustomTable