import React from 'react';
import { Select, Chip, MenuItem } from '@mui/material';

export default function MultipleSelectDropdown({ value, setValue, selectablesList, isEditing }) {
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setValue(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div >
            <Select
                multiple                
                style={{ height: "40px" }}
                value={value}
                onChange={handleChange}
                renderValue={(selected) =>
                    selected.map(
                        value =>
                            <Chip key={value} label={value} style={{ marginRight: "5px" }} />
                    )
                }
                disabled={!isEditing}
            >
                {selectablesList.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                    >
                        <span>{name}</span>
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}
