import EmptyBox from "../assets/empty-box.png";

const EmptyComponent = () => {
    return (
        <div className="flex h-full justify-center items-center">
            <div className="text-center p-4">
                <img  src={EmptyBox} alt="Empty Box" width="250px" />
                {/* <span className="font-semibold text">No data found</span> */}
            </div>
        </div>
    )
}

export default EmptyComponent