import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './contexts/ContextProvider';

import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';


const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createTheme();
root.render(
  <ContextProvider>
    <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
    </ThemeProvider>
  </ContextProvider>
);