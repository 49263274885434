import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.firebaseAPIKey,
  authDomain: "halal-meat-company.firebaseapp.com",
  projectId: "halal-meat-company",
  storageBucket: "halal-meat-company.appspot.com",
  messagingSenderId: "554244139120",
  appId: "1:554244139120:web:fa91fe1fa7949acab16cb5",
  measurementId: "G-X4X19R7BS5"
};

const app = initializeApp(firebaseConfig);


export const storage = getStorage(app);
export const db = getFirestore(app);
