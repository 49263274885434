import { Paper, } from "@mui/material"
import DataTable from 'react-data-table-component'

const columns = [
    {
        name: "Name",
        selector: (row, index) => row['item'].name,
    },
    {
        name: "Quantity",
        selector: (row, index) => row['quantity'],
    },
    {
        name: "Variant",
        selector: (row, index) => row['selectedVariant'] === undefined ? "NA" : row['selectedVariant'].name,
    },
    {
        name: "Add ons",
        selector: (row) => {
            if (row['selectedAddOns'].length === 0) {
                return "None";
            }
            else {
                return row['selectedAddOns'].map((e, index) => {
                    if (index === (row['selectedAddOns'].length - 1)) {
                        return e.name;
                    } else {
                        return e.name + ", ";
                    }
                });
            }
        },
    },
    {
        name: "Extras",
        selector: (row) => {
            if (row['selectedFOW'].length === 0) {
                return "None";
            }
            else {
                return row['selectedFOW'].map((e, index) => {
                    if (index === (row['selectedFOW'].length - 1)) {
                        return e.name;
                    } else {
                        return e.name + ", ";
                    }
                });
            }
        }
    },
]

export default function OrderList({ orderList }) {
    return (
        <div>
            <Paper style={{ margin: "10px" }}>
                <DataTable
                    title="Items"
                    columns={columns}
                    data={orderList}
                    defaultSortField="Price"
                    pagination
                    pointerOnHover
                    highlightOnHover
                    responsive
                    striped
                />
            </Paper>
        </div>
    )
}
