import { TextField, MenuItem, Alert } from "@mui/material";
import { motion } from "framer-motion";

export default function MenuHeader({ name, category, setCategory, price,
    setPrice, isEditing, status, setStatus, description, setDescription, }) {

    const handleDescriptionChange = event => {
        setDescription(event.target.value);
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    function getAlertType(status) {
        if (status === "pending") {
            return "info";
        } else if (status === "verified") {
            return "success";
        } else {
            return "error";
        }
    }

    return (
        <motion.div className="card"
            animate={{ padding: isEditing ? "10px" : "11px" }}
            transition={{ duration: 0.2 }}
        >
            <div className="flex flex-row items-center mb-2">
                <h1 className="text-xl font-bold">{name}</h1>
                <span className="mx-2">starting from</span>
                {isEditing ?
                    <TextField
                        className="w-20"
                        value={price}
                        size="small"
                        type="number"
                        onChange={(event) => setPrice(parseFloat(event.target.value))}
                    />
                    :
                    <span className="border p-2">£{price.toFixed(2)}</span>
                }
                {isEditing ?
                    <TextField
                        value={category}
                        size="small"
                        onChange={(event) => setCategory(event.target.value)}
                    />
                    :
                    <span className="border p-2">{category}</span>
                }
            </div>
            <Alert className="mb-4" severity={getAlertType(status)}>
                <span>Item verification status:</span>
                {isEditing ?
                    <TextField
                        select
                        size="small"
                        value={status}
                        onChange={handleStatusChange}
                    >
                        {["pending", "verified", "rejected"].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    :
                    <strong> {status.toUpperCase()}</strong>
                }
            </Alert>
            <TextField
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={description}
                disabled={!isEditing}
                onChange={handleDescriptionChange}
            />
        </motion.div>
    )
}
