import {
    TextField, Chip, Button,
    RadioGroup, Radio, FormControlLabel,
    IconButton, styled
} from "@mui/material";
import { Cancel } from '@mui/icons-material'
import { useState } from 'react';
import { getStatusColor } from "../../values/helper_functions";

export default function Ingredients({ isEditing, ingredients, setIngredients }) {
    const [newIngredient, setNewIngredient] = useState("");

    const handleIngredientDelete = ingredient => {
        setIngredients(
            ingredients.filter((ing) => ing.name !== ingredient)
        );
    }

    const handleNewIngredient = () => {
        let data = ingredients;
        data.push({ name: newIngredient, status: "not_halal" });
        setIngredients(data);
        setNewIngredient("");
    }

    const handleStatusChange = (newStatus, index) => {
        let temp = [...ingredients];
        temp[index].status = newStatus;
        setIngredients(temp);
    };

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)(({ status }) => ({
        backgroundColor: getStatusColor(status),
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: getStatusColor(status),
        },
    }));

    function BpRadio(props) {
        return (
            <Radio
                sx={{
                    '&:hover': {
                        bgcolor: 'transparent',
                    },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon status={props.status} />}
                icon={< BpIcon />}
                {...props}
            />
        );
    }

    return (
        <div className="card">
            <h3>Ingredients</h3>
            {
                ingredients.length === 0 ?
                    <span>No ingredients Added</span>
                    :
                    ingredients.map((ingredient, index) =>
                        <div key={index} className="flex flex-row items-center">
                            {isEditing ?
                                <RadioGroup
                                    row
                                    value={ingredient.status}
                                    onChange={(event, value) => handleStatusChange(value, index)}
                                    style={{
                                        marginRight: "10px",
                                    }}
                                >
                                    <FormControlLabel value="doubtful" control={<BpRadio status={"doubtful"} />} label="Doubtful" />
                                    <FormControlLabel value="halal" control={<BpRadio status="halal" />} label="Halal" />
                                    <FormControlLabel value="not_halal" control={<BpRadio status="not_halal" />} label="Not Halal" />
                                </RadioGroup>
                                :
                                <></>
                            }
                            <Chip
                                key={ingredient.name}
                                label={<h3>{ingredient.name}</h3>}
                                style={{
                                    backgroundColor: getStatusColor(ingredient.status),
                                    color: "white",
                                    marginTop: "10px",
                                }}
                            />
                            {
                                isEditing ?
                                    <IconButton onClick={() => handleIngredientDelete(ingredient.name)}>
                                        <Cancel />
                                    </IconButton>
                                    :
                                    <></>
                            }
                        </div>
                    )
            }
            <div className="row__start">
                <TextField
                    size="small"
                    variant="outlined"
                    placeholder="Enter Ingredient"
                    value={newIngredient}
                    disabled={!isEditing}
                    onChange={(event) => setNewIngredient(event.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleNewIngredient();
                        }
                    }}
                    style={{ marginTop: "10px" }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNewIngredient}
                    disabled={!isEditing}
                    style={{ marginTop: "8px", marginLeft: "10px" }}
                >
                    Add
                </Button>
            </div>
        </div>
    )
}
