import CustomerDetail from "../components/Order/CustomerDetail";
import OrderHeader from "../components/Order/OrderHeader";
import OrderInfo from "../components/Order/OrderInfo";
import OrderList from "../components/Order/OrderList";
import Scaffold from "../components/Scaffold";


import { useParams, useNavigate } from 'react-router-dom';
import { IconButton } from "@mui/material";
import { getDoc, doc } from "firebase/firestore";
import { ArrowBack } from "@mui/icons-material";
import { useState, useEffect } from 'react';
import { db } from "../firebase-config";

export default function OrderDetail() {
    const navigate = useNavigate();
    const { collectionName, id, isHistory, oid } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isEditing] = useState(false);
    const [isError, setIsError] = useState(false);

    const [order, setOrder] = useState({});
    const [cart, setCart] = useState({});
    const [shop, setShop] = useState({});
    const [orderList, setOrderList] = useState([]);


    useEffect(() => {
        const getOrders = async () => {
            setIsLoading(true);
            try {
                let path = `${collectionName}/${id}/${isHistory === "0" ? "orders" : "history"}/${oid}`;
                const docRef = doc(db, path);
                const docData = await getDoc(docRef);
                const data = docData.data();

                setOrder(data);
                setCart(data.cart);
                setShop(data.cart.shop);
                setOrderList(data.cart.orderList);

                document.title = `Order ${data['status']}`;
            } catch (e) {
                console.log(e);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getOrders();
    }, [collectionName, isHistory, id, oid]);

    return (
        <Scaffold
            isLoading={isLoading}
            isError={isError}
        >
            <IconButton onClick={() => {
                navigate(-1);
            }}>
                <ArrowBack />
            </IconButton>


            <div className="flex flex-col 2xl:flex-row">

                <div className="flex-auto 2xl:w-4/5">
                    <OrderHeader shop={shop} order={order} cart={cart} />
                    <CustomerDetail isEditing={isEditing} order={order} />
                    <OrderList orderList={orderList} />
                </div>
                <div className="flex-auto">
                    <OrderInfo order={order} />
                </div>
            </div>
        </Scaffold>
    );
}
