import MenuHeader from "../components/Menu/MenuHeader";
import DetailsTable from "../components/Menu/DetailsTable";
import IngredientsSection from "../components/Menu/IngredientsSection";
import Scaffold from "../components/Scaffold";
import HeaderButtons from "../components/HeaderButtons";

import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db } from "../firebase-config";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useStateContext } from "../contexts/ContextProvider";


export default function MenuDetail() {
    const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();
    const { collectionName, sid, mid } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);

    const [menuItem, setMenuItem] = useState({});

    const [price, setPrice] = useState(0);
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");
    const [ingredients, setIngredients] = useState([]);
    const [variants, setVariants] = useState([]);
    const [addons, setAddons] = useState([]);
    const [fow, setFow] = useState([]);
    const [status, setStatus] = useState("");


    useEffect(() => {
        const getMenuItem = async () => {
            setIsLoading(true);

            try {
                const docRef = doc(db, `${collectionName}/${sid}/menu/${mid}`);
                const docData = await getDoc(docRef);
                const data = docData.data();
                setMenuItem(data);
                setVariables(data);

                document.title = `${data['name']}`;
            }
            catch (e) {
                console.log(e);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getMenuItem();
    }, [sid, mid]);

    const setVariables = data => {
        setPrice(data['price']);
        setCategory(data['category']);
        setDescription(data['description']);
        setIngredients(data['ingredients']);
        setVariants(data['variants']);
        setAddons(data['addOns']);
        setFow(data['frequentlyOrderedWith']);
        setStatus(data['status']);
    }

    const updateMenuItem = async () => {
        setIsLoading(true);

        try {
            const docRef = doc(db, `${collectionName}/${sid}/menu/${mid}`);
            await updateDoc(
                docRef,
                {
                    'price': price ?? null,
                    'category': category ?? null,
                    'description': description ?? null,
                    'ingredients': ingredients ?? null,
                    'variants': variants ?? null,
                    'addOns': addons ?? null,
                    'frequentlyOrderedWith': fow ?? null,
                    'status': status ?? null,
                }
            );
            setAlertMessage("Updated successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setAlertMessage("Failed to update! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }

        setIsLoading(false);
    }
    return <Scaffold
        isLoading={isLoading}
        isError={isError}
    >
        <HeaderButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            data={menuItem}
            updateData={updateMenuItem}
            setVariables={setVariables}
        />
        <div className="flex flex-col lg:flex-row">
            <div className="flex-auto lg:w-96 2xl:w-400">
                <MenuHeader
                    name={menuItem['name']}
                    category={category}
                    setCategory={setCategory}
                    price={price}
                    setPrice={setPrice}
                    isEditing={isEditing}
                    status={status}
                    setStatus={setStatus}
                    description={description}
                    setDescription={setDescription}
                />

                <DetailsTable
                    variants={variants}
                    setVariants={setVariants}
                    addons={addons}
                    setAddons={setAddons}
                    fow={fow}
                    setFow={setFow}
                    isEditing={isEditing}
                />
            </div>

            <div className="flex-auto lg:w-32 2xl:w-8">
                <IngredientsSection
                    isEditing={isEditing}
                    ingredients={ingredients}
                    setIngredients={setIngredients}
                />
                {
                    menuItem && menuItem["imagePath"] && <div className="card">
                        <img
                            loading="lazy"
                            className="w-full"
                            src={menuItem["imagePath"]}
                            alt="Menu Item"
                        />
                    </div>
                }
            </div>
        </div>
    </Scaffold>
}
