import { useEffect } from 'react';

import React from 'react'

const Dashboard = () => {
  useEffect(() => {
    try { }
    catch (e) {
      console.log(e);
    }
    finally { }
    document.title = `Dashboard`;
  });


  return (
    <h1>Dashboard</h1>
  )
}

export default Dashboard
