import React from 'react'
import { TextField } from '@mui/material'

export default function EditableTextField({ label, value, setValue, helperText,isEditing, InputProps }) {
    return (
        <TextField
            label={label}
            variant="outlined"
            size="small"
            required
            fullWidth
            value={value}
            helperText={helperText}
            onChange={(event) => setValue(event.target.value)}
            disabled={!isEditing}
            InputProps={InputProps}
            style={{
                marginRight: "10px", marginBottom: "10px",
            }}
        />
    )
}
