export function getStatusColor(status) {
    if (status === "pending") {
        return "#9ea7adFF";
    } else if (status === "verified" || status === "halal" || status === "published") {
        return "#00A36CFF";
    } else if (status === "doubtful") {
        return "#FFC107FF"
    } else {
        return "#ff3333FF";
    }
}

export function getOrderStatusColor(status) {
    if (status === "delivered" || status === "picked") {
        return "#00A36CFF";
    }
    else if (status === "preparing") {
        return "#fce83aFF";
    } else if (status === "prepared") {
        return "#ffb302FF";
    } else if (status === "rejected") {
        return "#ff3333FF";
    }

    return "#9ea7adFF";
}

export const getDate = epochs => {
    var date = new Date(0);
    date.setUTCMilliseconds(epochs);
    return date.toLocaleDateString();
}

export const getTime = epochs => {
    var date = new Date(0);
    date.setUTCMilliseconds(epochs);
    date.toLocaleTimeString();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime =
        (hours < 10 ? `0${hours}` : hours)
            + ':' + (minutes < 10 ? `0${minutes}` : minutes)
            + ' ' + ampm;
    return strTime;
}