import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import { getDate, getTime, getOrderStatusColor } from "../../values/helper_functions";

export default function OrderInfo({ order }) {
    let rows = [
        {
            date: getDate(order.placedTime),
            time: getTime(order.placedTime),
            color: getOrderStatusColor("pending"),
            status: "Order Received",
        },
    ];

    const getRows = () => {
        let list = [...rows];
        if (order.rejectedTime !== undefined) {
            list = [...list, {
                date: getDate(order.rejectedTime),
                time: getTime(order.rejectedTime),
                color: getOrderStatusColor("rejected"),
                status: "Order Rejected",
            }];
        }
        if (order.cancelledTime !== undefined) {
            list = [...list, {
                date: getDate(order.cancelledTime),
                time: getTime(order.cancelledTime),
                color: getOrderStatusColor("cancelled"),
                status: "Order Cancelled",
            }];
        }
        if (order.acceptedTime !== undefined) {
            list = [...list, {
                date: getDate(order.acceptedTime),
                time: getTime(order.acceptedTime),
                color: getOrderStatusColor("preparing"),
                status: "Order Accepted",
            }];
        }
        if (order.readyTime !== undefined) {
            list = [...list, {
                date: getDate(order.readyTime),
                time: getTime(order.readyTime),
                color: getOrderStatusColor("prepared"),
                status: "Order Ready",
            }];
        }
        if (order.deliveryTime !== undefined) {
            list = [...list, {
                date: getDate(order.deliveryTime),
                time: getTime(order.deliveryTime),
                color: getOrderStatusColor("delivered"),
                status: "Order Delivered",
            }];
        }
        return list;
    }

    return (
        <div className='card'>
            <span className='text-xl font-bold'>{getRows()[0].date}</span>
            <Timeline style={{ padding: "0px" }}>
                {
                    getRows().map((row, index) => {
                        return <TimelineItem key={row.status}>
                            <TimelineOppositeContent color="text.secondary">
                                {row.time}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot style={{ backgroundColor: row.color }} />
                                {(index < getRows().length - 1) && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>{row.status}</TimelineContent>
                        </TimelineItem>
                    })
                }
            </Timeline>
        </div>
    )
}
