import { Alert } from "@mui/material";
import { getOrderStatusColor } from "../../values/helper_functions";

const OrderHeader = ({ order, shop, cart }) => {
    return (
        <div className="card">
            <div className="flex flex-row items-center mb-4">
                <h1 className="text-3xl font-bold">{shop.name}</h1>
                <span
                    className="mx-3"
                    style={{
                        backgroundColor: getOrderStatusColor(order.status),
                        fontSize: "0.90rem",
                        color: "white",
                        borderRadius: "8px",
                        padding: "5px 13px",
                        display: "inline-block"
                    }}
                >
                    {order.status}
                </span>
            </div>
            <span className="border border-gray-400 text-white rounded-lg px-3 py-1 bg-gray-500">
                {order.id}
            </span>
            <Alert className="mt-2" severity="info"
            >
                This is a <strong>{cart.deliveryMode}</strong> order
            </Alert>
        </div>
    )
}

export default OrderHeader