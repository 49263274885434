import { useState } from 'react';
import {
    Button, Dialog, DialogContent, DialogTitle,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { db, storage } from "../../firebase-config";
import Placeholder from "../../assets/placeholder.png";
import { useStateContext } from "../../contexts/ContextProvider";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
} from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";



const ImageSection = ({ barcode, setIsLoading, itemImagePath,
    setItemImagePath, ingredientsImagePath, setIngredientsImagePath
}) => {
    const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const [itemImageFile, setItemImageFile] = useState("");
    const [ingredientImageFile, setIngredientImageFile] = useState("");

    const imagesList = [
        {
            key: 'itemImagePath',
            imagePath: itemImagePath,
            setImagePath: setItemImagePath,
            imageFile: itemImageFile,
            setImageFile: setItemImageFile,
        },
        {
            key: 'ingredientsImagePath',
            imagePath: ingredientsImagePath,
            setImagePath: setIngredientsImagePath,
            imageFile: ingredientImageFile,
            setImageFile: setIngredientImageFile,
        }
    ];

    function handleUpload(dataKey, file) {
        if (!file) {
            setAlertMessage("Please choose a file to upload");
            setAlertType("error");
            setOpenAlert(true);
            return;
        }

        try {
            const storageRef = ref(storage, `/${barcode}/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            setIsLoading(true);

            uploadTask.on(
                "state_changed",
                (snapshot) => { },
                (err) => {
                    console.log(err);
                    setIsLoading(false);
                    setAlertMessage("Error uploading! Try again.");
                    setAlertType("error");
                    setOpenAlert(true);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => updateImagePath(dataKey, url));
                }
            );
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlertMessage("Error uploading! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }

    }

    const updateImagePath = async (key, url) => {
        setIsLoading(true);
        try {
            const docRef = doc(db, `scannedItems/${barcode}`);
            if (key === "itemImagePath") {
                await updateDoc(docRef, { "itemImagePath": url });
            } else if (key === "ingredientsImagePath") {
                await updateDoc(docRef, { "ingredientsImagePath": url });
            }
            setItemImagePath(url);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlertMessage("Error updating! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }
        setIsLoading(false);
    }

    const deleteImage = async (key, url) => {
        try {
            setIsLoading(true);

            const storageRef = ref(storage, url);
            await deleteObject(storageRef);
            await updateImagePath(key, "");

            setAlertMessage("Deleted successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlertMessage("Error uploading! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }

        setIsLoading(false);
    }

    const editImage = async () => {
        const storageRef = ref(storage, imagesList[selectedIndex].imagePath);
        await deleteObject(storageRef);
        handleUpload(
            imagesList[selectedIndex].key,
            imagesList[selectedIndex].imageFile,
        );
    }
    
    return (
        <div className='m-2'>
            <div className="flex flex-row">
                {
                    imagesList.map((image, index) => {
                        return image.imagePath === "" ?
                            <div key={image.key} className='mr-2 rounded-lg w-400'>
                                <img className='rounded-lg shadow-md mb-2' src={Placeholder} alt="placeholder" />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={event => image.setImageFile(event.target.files[0])} />
                                <Button
                                    variant='contained'
                                    onClick={() => handleUpload(image.key, image.imageFile)}
                                >
                                    Upload
                                </Button>
                            </div>
                            :
                            <div key={image.key} className="card p-0 flex flex-col items-end">
                                <div className='flex'>
                                    <Button onClick={() => deleteImage(image.key, image.imagePath)}>
                                        Delete
                                    </Button>
                                    <Button onClick={() => {
                                        setSelectedIndex(index);
                                        setOpenDialog(true);
                                    }}>
                                        Edit
                                    </Button>
                                </div>
                                <img src={image.imagePath} alt="Scanned Item" />
                            </div>
                    })
                }
            </div>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Edit Image</DialogTitle>
                <DialogContent>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={event => imagesList[selectedIndex].setImageFile(event.target.files[0])}
                    />
                    <Button
                        variant='contained'
                        onClick={() => editImage()}
                    >
                        Upload
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ImageSection