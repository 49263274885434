import { TextField, MenuItem, Alert } from "@mui/material";
import { motion } from "framer-motion";

export default function ShopHeader({ shop, rating,
    totalRatings, orders, isEditing, status, setStatus }) {
    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    function getAlertType(status) {
        if (status === "pending") {
            return "info";
        } else if (status === "verified") {
            return "success";
        } else {
            return "error";
        }
    }


    return (
        <motion.div
            className="card"
            animate={{ padding: isEditing ? "15px" : "10px" }}
        >
            <span className="text-xl font-bold">{shop['name']}</span>

            <div className="mt-2">
                <Alert className="mb-2" severity="info"
                >
                    {rating.toFixed(2)}⭐ based on {totalRatings} ratings from {orders} orders
                </Alert>

                <Alert severity={getAlertType(status)}>
                    <div className="flex items-start">
                        <span className=" pr-1">Restaurant verification status:</span>
                        {isEditing ?
                            <TextField
                                select
                                size="small"
                                value={status}
                                onChange={handleStatusChange}
                            >
                                {["pending", "verified", "rejected"].map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            :
                            <strong>{status.toUpperCase()}</strong>
                        }
                    </div>
                </Alert>
            </div>
        </motion.div>
    )
}
