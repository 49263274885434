import EditableTextField from '../components/EditableTextField';
import HeaderButtons from "../components/HeaderButtons";
import Scaffold from "../components/Scaffold";

import {
    Radio, RadioGroup, FormControlLabel, FormControl, Checkbox,
    TextField, Grid, InputAdornment,
} from '@mui/material';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useStateContext } from "../contexts/ContextProvider";
import {
    getDoc, doc, deleteDoc, updateDoc,
} from "firebase/firestore";
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db } from "../firebase-config";

export default function PromotionDetail() {
    const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();
    const navigate = useNavigate();
    const { code } = useParams();

    const [promotion, setPromotion] = useState();

    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);

    const [promoCode, setPromoCode] = useState("");
    const [deliveryFeeCoupon, setDeliveryFeeCoupon] = useState(false);
    const [discountsPerUser, setDiscountsPerUser] = useState(0);
    const [numberOfDiscounts, setNumberOfDiscounts] = useState(0);
    const [expiryDate, setExpiryDate] = useState(0);
    const [message, setMessage] = useState("");
    const [minimunOrderPrice, setMinimunOrderPrice] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [usersAlreadyConsumed, setUsersAlreadyConsumed] = useState([]);


    useEffect(() => {
        const getUser = async () => {
            setIsLoading(true);

            try {
                const docRef = doc(db, `Promotions/${code}`);
                const docData = await getDoc(docRef);
                const data = docData.data();
                setPromotion(data);
                setVariables(data);

                document.title = `${data['code']}`;
            } catch (e) {
                console.log(e);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getUser();
    }, [code]);

    const setVariables = data => {
        setPromoCode(data['code']);
        setDeliveryFeeCoupon(data['deliveryFeeCoupon'] ?? false);
        setDiscountsPerUser(data['discountsPerUser']);
        setExpiryDate(data['expiryDate']);
        setMessage(data["message"]);
        setMinimunOrderPrice(data['minimunOrderPrice']);
        setDiscountAmount(data['discountAmount']);
        setUsersAlreadyConsumed(data['usersAlreadyConsumed']);
    }

    const updatePromotion = async () => {
        setIsLoading(true);

        try {
            const data = {
                "deliveryFeeCoupon": deliveryFeeCoupon ?? null,
                "discountsPerUser": discountAmount ?? null,
                "expiryDate": expiryDate ?? null,
                "message": message ?? null,
                "minimunOrderPrice": minimunOrderPrice ?? null,
                "discountAmount": discountAmount ?? null,
                "usersAlreadyConsumed": usersAlreadyConsumed ?? null,
            };
            const docRef = doc(db, `Promotions/${promoCode}`);
            await updateDoc(docRef, data);
            setAlertMessage("Updated successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setAlertMessage("Failed to update! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }

        setIsLoading(false);
    }

    const deletePromotion = async () => {
        try {
            setIsLoading(true);

            const docRef = doc(db, `Promotions/${promoCode}`);
            await deleteDoc(docRef);

            setIsLoading(false);
            navigate(-1);
            setAlertMessage("Deleted successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlertMessage("Error deleting! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }
    }

    return <Scaffold
        isError={isError}
        isLoading={isLoading}
    >
        <HeaderButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            data={promotion}
            showDelete={!isEditing}
            onDelete={deletePromotion}
            updateData={updatePromotion}
            setVariables={setVariables}
        />

        <div className="card">
            <h1 className="text-xl font-bold">{promoCode}</h1>

            <FormControl>
                <RadioGroup
                    value={deliveryFeeCoupon}
                    onChange={event => setDeliveryFeeCoupon(event.target.value)}
                >
                    <div className="flex flex-row">
                        <FormControlLabel value={true} control={<Radio />} label="Free delivery coupon" />
                        <FormControlLabel value={false} control={<Radio />} label="Discount Coupon" />
                    </div>
                </RadioGroup>
            </FormControl>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <EditableTextField
                        label="Message"
                        value={message}
                        setValue={setMessage}
                        isEditing={isEditing}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <MobileDatePicker
                        label="Expiry date"
                        inputFormat="MM/dd/yyyy"
                        disabled={!isEditing}
                        size="small"
                        value={expiryDate}
                        onChange={setExpiryDate}
                        renderInput={(params) => <TextField
                            className='w-full'
                            size='small'
                            {...params}
                        />}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <EditableTextField
                        label="Minimum order price"
                        value={minimunOrderPrice}
                        setValue={setMinimunOrderPrice}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                        }}
                        isEditing={isEditing}

                    />
                </Grid>
            </Grid>
            {
                !deliveryFeeCoupon &&
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <EditableTextField
                            label="Discounts per user"
                            value={discountsPerUser}
                            setValue={setDiscountsPerUser}
                            isEditing={isEditing}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <EditableTextField
                            label="Total discounts"
                            value={numberOfDiscounts}
                            setValue={setNumberOfDiscounts}
                            isEditing={isEditing}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <EditableTextField
                            label="Discount amount"
                            value={discountAmount}
                            setValue={setDiscountAmount}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">£</InputAdornment>
                            }}
                            isEditing={isEditing}

                        />
                    </Grid>
                </Grid>
            }
            {
                usersAlreadyConsumed &&
                <div>
                    <h3 className="text-xl font-bold">Users Consumed</h3>
                    {
                        usersAlreadyConsumed.map((user) =>
                            <span key={user}>{user}</span>
                        )
                    }
                </div>
            }
        </div>
    </Scaffold>;
}
