import * as React from 'react';
import Scaffold from '../components/Scaffold';
import CustomTable from '../components/CustomTable';

import { getStatusColor } from "../values/helper_functions";
import {
    collection, getDoc, onSnapshot, setDoc, doc
} from "firebase/firestore";
import { useState, useEffect, useRef } from 'react';
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import {
    IconButton, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, TextField, Button,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { db } from "../firebase-config";

const columns = [
    {
        title: "Barcode",
        field: "barcode",
        width: "200px",
    },
    {
        title: "Name",
        field: "name",
        width: "200px",
    },
    {
        title: "Publication Status",
        field: "publicationStatus",
        width: "150px",
    },
    {
        title: "Halal Status",
        field: "halalStatus",
        width: "150px",
    },
];

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: "650px",
    },
    tableContainer: {
        borderRadius: "8px",
    },
    tableHeaderCell: {
        fontWeight: "bold",
        letterSpacing: 1,
        backgroundColor: "#42a5f5FF",
        color: theme.palette.getContrastText("#42a5f5FF")
    },
    halalStatus: {
        fontSize: "0.90rem",
        color: "white",
        backgroundColor: "gray",
        borderRadius: "8px",
        padding: "5px 13px",
        display: "inline-block"
    },
    publicationStatus: {
        fontSize: "0.90rem",
        color: "white",
        backgroundColor: "gray",
        borderRadius: "8px",
        padding: "5px 13px",
        display: "inline-block"
    }
}));

const getStatus = (ingredients, halalStatus) => {
    if ((ingredients ?? []).length > 0) {
        if ((ingredients.map((e) => e.status).includes("not_halal"))) {
            return "not_halal";
        } else if ((ingredients.map((e) => e.status).includes("doubtful"))) {
            return "doubtful";
        } else {
            return "halal";
        }
    } else {
        return halalStatus === "" ? "not_halal" : halalStatus;
    }
}

export default function ScannedItemsList() {
    const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();
    const classes = useStyles();

    const [barcode, setBarcode] = useState("");

    const [openDialog, setOpenDialog] = useState(false);

    const [firestoreData, setFirestoreData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const snapshot = useRef(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        try {
            if (snapshot.current === undefined) {
                snapshot.current = onSnapshot(
                    collection(db, "scannedItems"),
                    (snapshot) => {
                        setFirestoreData([...snapshot.docs.map((doc) => doc.data())]);
                        setIsLoading(false);
                    });
            }
        } catch (e) {
            console.log(e);
        }

        document.title = "Scanned Items";
    }, []);

    const addScannedItem = async () => {
        try {
            const data = {
                'barcode': barcode,
                'userId': "admin",
                'userName': "Admin",
                'halalStatus': "not_halal",
                'publicationStatus': "pending",
            };
            const docRef = doc(db, `scannedItems/${barcode}`);
            if ((await getDoc(docRef)).exists()) {
                setOpenDialog(false);
                setAlertMessage("An item with the same barcode already exists.");
                setAlertType("error")
                setOpenAlert(true);
            } else {
                setOpenDialog(false);
                setIsLoading(true);
                await setDoc(docRef, data);
                setIsLoading(false);
                navigate(`scanned_item_detail/${barcode}`);
            }
            setBarcode("");
        } catch (e) {
            console.log(e);
            alert("Failed");
        }
    }

    return <Scaffold
        isLoading={isLoading}
    >
        <CustomTable
            columns={columns}
            data={firestoreData}
            classes={classes}
            onClick={(row) => navigate("scanned_item_detail/" + row.barcode)}
            titleWidget={
                <IconButton
                    onClick={() => setOpenDialog(true)}
                >
                    <Add />
                </IconButton>
            }
            styledCell={
                (row, cell) => <span
                    className={classes[cell.field]}
                    style={
                        cell.field === "publicationStatus" ? {
                            backgroundColor: getStatusColor(row["publicationStatus"])
                        }
                            :
                            cell.field === "halalStatus" ? {
                                backgroundColor: getStatusColor(getStatus((row["ingredients"] ?? []), (row["halalStatus"] ?? "")))
                            } :
                                {}
                    }
                >
                    {
                        cell.field === "publicationStatus" ?
                            (row[cell.field] ?? "pending").replace("_", " ")
                            : cell.field === "halalStatus" ?
                                getStatus((row["ingredients"] ?? []), (row["halalStatus"] ?? "")).replace("_", " ")
                                :
                                row[cell.field] ?? "NA"
                    }
                </span>
            }
        />

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle>Enter Barcode</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter barcode of the new item. Remember this code can't be
                    changed after this step.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Barcode"
                    fullWidth
                    variant="standard"
                    value={barcode}
                    onChange={event => setBarcode(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpenDialog(false);
                    setBarcode("");
                }}>Cancel</Button>
                <Button onClick={() => addScannedItem()}>Done</Button>
            </DialogActions>
        </Dialog>
    </Scaffold>
}

