import { TextField, Chip, Button } from "@mui/material"
import { useState } from 'react';

export default function IngredientsSection({ isEditing, ingredients, setIngredients }) {
    const [newIngredient, setNewIngredient] = useState("");

    const handleIngredientDelete = ingredient => {
        setIngredients(
            ingredients.filter((ing) => ing !== ingredient)
        );
    }

    const handleNewIngredient = () => {
        let data = ingredients;
        data.push(newIngredient);
        setIngredients(data);
        setNewIngredient("");
    }

    return (
        ingredients &&
        <div className="card">
            <div>
                <h3 className='text-xl font-bold mb-2'>Ingredients</h3>

                <div className="row__start">
                    <TextField
                        size="small"
                        variant="outlined"
                        placeholder="Enter Ingredient"
                        value={newIngredient}
                        disabled={!isEditing}
                        onChange={(event) => setNewIngredient(event.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleNewIngredient();
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNewIngredient}
                        disabled={!isEditing}
                        style={{ padding: "8px", marginLeft: "8px" }}
                    >
                        Add
                    </Button>
                </div>

                {
                    ingredients.length === 0 ?
                        <span>No ingredients Added</span>
                        :
                        ingredients.map((ingredient, index) =>
                            <Chip
                                key={index}
                                label={<span>{ingredient}</span>}
                                onDelete={isEditing ? () => handleIngredientDelete(ingredient) : null}
                                style={{ marginTop: "10px", marginRight: "10px" }}
                            />
                        )
                }
            </div>
        </div>
    )
}
