import * as React from 'react';
import Scaffold from '../components/Scaffold';
import CustomTable from '../components/CustomTable';

import { collection, onSnapshot } from "firebase/firestore";
import { getStatusColor } from '../values/helper_functions';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { db } from "../firebase-config";

const columns = [
  {
    title: "Id",
    field: "id",
    width: "300px",
  },
  {
    title: "Name",
    field: "name",
    width: "200px",
  },
  {
    title: "Address",
    field: "address",
    width: "200px",
  },
  {
    title: "Rating",
    field: "rating",
    width: "80px",
  },
  {
    title: "Orders",
    field: "orders",
    width: "80px",
  },
  {
    title: "Status",
    field: "status",
    width: "100px",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: "650px",
  },
  tableContainer: {
    borderRadius: "8px",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    letterSpacing: 1,
    backgroundColor: "#42a5f5FF",
    color: theme.palette.getContrastText("#42a5f5FF")
  },
  status: {
    fontSize: "0.90rem",
    color: "white",
    backgroundColor: "gray",
    borderRadius: "8px",
    padding: "5px 13px",
    display: "inline-block"
  }
}));

export default function ShopsList() {
  const { collectionName } = useParams();
  const snapshot = useRef(undefined);
  const navigate = useNavigate();
  const classes = useStyles();

  const [firestoreData, setFirestoreData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log(collectionName);
    snapshot.current = onSnapshot(
      collection(db, collectionName),
      (snapshot) => {
        setFirestoreData([...snapshot.docs.map((doc) => doc.data())]);
        setIsLoading(false);
      });

    document.title = collectionName.charAt(0).toUpperCase() + collectionName.slice(1);
  }, []);

  return <Scaffold
    isLoading={isLoading}
  >
    <CustomTable
      columns={columns}
      data={firestoreData}
      classes={classes}
      onClick={(row) => navigate(`shop_detail/${row.id}`)}
      styledCell={
        (row, cell) => <span
          className={classes[cell.field]}
          style={cell.field === "status" ? {
            backgroundColor: getStatusColor(row[cell.field])
          } : {}}
        >
          {row[cell.field] ?? "NA"}
        </span>
      }
    />
  </Scaffold>
}
