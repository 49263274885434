import React, {
    createContext, useContext,
    useState
} from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
    const [activeMenu, setActiveMenu] = useState(true);
    const [screenSize, setScreenSize] = useState(undefined);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("info");

    return (<StateContext.Provider
        value={{
            activeMenu,
            setActiveMenu,
            screenSize,
            setScreenSize,
            openAlert,
            setOpenAlert,
            alertMessage,
            setAlertMessage,
            alertType,
            setAlertType,
        }}
    >
        {children}
    </StateContext.Provider>
    );
}

export const useStateContext = () => useContext(StateContext);