import EditableTextField from '../EditableTextField';
import MultipleSelectDropdown from '../MultipleSelectDropdown';

import { InputAdornment, Grid } from "@mui/material";
import { motion } from "framer-motion";

export default function DetailsForm({
    isEditing, address, setAddress, openingTime, setOpeningTime,
    closingTime, setClosingTime, deliveryModes, setDeliveryModes,
    deliveryFee, setDeliveryFee, freeDeliveryAt, setFreeDeliveryAt,
    deliveryTime, setDeliveryTime, pickupTime, setPickupTime,
}) {

    function getParsedTime(time) {
        const saperated = time.split(":");
        if (parseInt(saperated[0]) === 24) {
            return `00:${saperated[1] < 10 ? `0${saperated[1]}` : saperated[1]} AM`;
        }
        else if (parseInt(saperated[0]) < 12) {
            return `${saperated[0] < 10 ? `0${saperated[0]}` : saperated[0]}:${saperated[1] < 10 ? `0${saperated[1]}` : saperated[1]} AM`;
        }
        else {
            return `${(saperated[0] % 12) < 10 ? `0${(saperated[0] % 12)}` : (saperated[0] % 12)}:${saperated[1] < 10 ? `0${saperated[1]}` : saperated[1]} PM`;
        }
    }

    return (
        <motion.div className="card"
            animate={{ padding: isEditing ? "15px" : "10px" }}
        >
            <h2 className='text-xl font-bold mb-3'>Details</h2>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EditableTextField
                        label="Address"
                        value={address}
                        setValue={setAddress}
                        isEditing={isEditing}

                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Opening Time"
                        value={openingTime}
                        setValue={setOpeningTime}
                        helperText={getParsedTime(openingTime)}
                        isEditing={isEditing}

                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Closing Time"
                        value={closingTime}
                        setValue={setClosingTime}
                        helperText={getParsedTime(closingTime)}
                        isEditing={isEditing}

                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Delivery Fee"
                        value={deliveryFee}
                        setValue={setDeliveryFee}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                        }}
                        isEditing={isEditing}

                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Free Delivery At"
                        value={freeDeliveryAt}
                        setValue={setFreeDeliveryAt}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">£</InputAdornment>
                        }}
                        isEditing={isEditing}

                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Delivery Time"
                        value={deliveryTime}
                        setValue={setDeliveryTime}
                        InputProps={{
                            endAdornment: < InputAdornment position="end">mins</InputAdornment>
                        }}
                        isEditing={isEditing}

                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Pickup Time"
                        value={pickupTime}
                        setValue={setPickupTime}
                        InputProps={{
                            endAdornment: < InputAdornment position="end">mins</InputAdornment>
                        }}
                        isEditing={isEditing}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MultipleSelectDropdown
                        value={deliveryModes}
                        setValue={setDeliveryModes}
                        selectablesList={["delivery", "pickup"]}
                        isEditing={isEditing}
                    />
                </Grid>
            </Grid>
        </motion.div>
    )
}
