import * as React from 'react';
import Scaffold from '../components/Scaffold';
import CustomTable from '../components/CustomTable';

import { collection, onSnapshot } from "firebase/firestore";
import { makeStyles } from "@mui/styles";
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from "../firebase-config";


const columns = [
    {
        title: "Name",
        field: "name",
        width: "200px",
    },
    {
        title: "Phone",
        field: "phoneNumber",
        width: "150px",
    },
    {
        title: "Email",
        field: "email",
        width: "150px",
    },
    {
        title: "Id",
        field: "id",
        width: "200px",
    },
];

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: "650px",
    },
    tableContainer: {
        borderRadius: "8px",
    },
    tableHeaderCell: {
        fontWeight: "bold",
        letterSpacing: 1,
        backgroundColor: "#42a5f5FF",
        color: theme.palette.getContrastText("#42a5f5FF")
    },
}));


export default function UsersList() {
    const classes = useStyles();

    const [firestoreData, setFirestoreData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const snapshot = useRef(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (snapshot.current === undefined) {
            snapshot.current = onSnapshot(
                collection(db, "users"),
                (snapshot) => {
                    setFirestoreData([...snapshot.docs.map((doc) => doc.data())]);
                    setIsLoading(false);
                });
        }
        document.title = "Users";
    }, []);


    return <Scaffold
        isLoading={isLoading}
    >
        <CustomTable
            columns={columns}
            data={firestoreData}
            classes={classes}
            onClick={(row) => navigate("user_detail/" + row.id)}
            styledCell={
                (row, cell) => <span>{row[cell.field] ?? "NA"}</span>
            }

        />
    </Scaffold>
}
