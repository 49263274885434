import ScannedItemHeader from '../components/ScannedItem/ScannedItemHeader';
import ImageSection from '../components/ScannedItem/ImageSection';
import Ingredients from '../components/ScannedItem/Ingredients';
import ItemInfo from '../components/ScannedItem/ItemInfo';
import HeaderButtons from "../components/HeaderButtons";
import Scaffold from "../components/Scaffold";

import {
    getDoc, doc, deleteDoc, updateDoc,
} from "firebase/firestore";
import {
    ref, deleteObject, listAll
} from "firebase/storage";
import { useStateContext } from "../contexts/ContextProvider";
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db, storage } from "../firebase-config";

export default function ScannedItemDetail() {
    const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();
    const navigate = useNavigate();
    const { barcode } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [scannedItem, setScannedItem] = useState();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [ingredients, setIngredients] = useState([]);
    const [halalStatus, setHalalStatus] = useState("");
    const [publicationStatus, setPublicationStatus] = useState("");
    const [itemImagePath, setItemImagePath] = useState("");
    const [ingredientsImagePath, setIngredientsImagePath] = useState("");
    const [dateAdded, setDateAdded] = useState("");
    const [lastUpdate, setLastUpdate] = useState("");
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");

    useEffect(() => {
        const getScannedItem = async () => {            
            setIsLoading(true);

            try {
                const docRef = doc(db, `scannedItems/${barcode}`);
                const docData = await getDoc(docRef);
                const data = docData.data();
                setScannedItem(data);
                setVariables(data);

                document.title = barcode;
            } catch (e) {
                console.log(e);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getScannedItem();
    }, [barcode]);


    const updateScannedItem = async () => {
        setIsLoading(true);

        try {
            const data = {
                'name': name ?? null,
                'description': description ?? null,
                'ingredients': ingredients ?? null,
                'halalStatus': halalStatus ?? null,
                'publicationStatus': publicationStatus ?? null,
                'itemImagePath': itemImagePath ?? null,
                'ingredientsImagePath': ingredientsImagePath ?? null,
                'lastUpdate': new Date().getTime(),
            };
            const docRef = doc(db, `scannedItems/${barcode}`);
            await updateDoc(docRef, data);
            setAlertMessage("Updated successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setAlertMessage("Failed to update! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }

        setIsLoading(false);
    }

    const deleteScannedItem = async () => {
        try {
            setIsLoading(true);

            const storageRef = ref(storage, `/${barcode}`);
            listAll(storageRef)
                .then(async (listResults) => {
                    const promises = listResults.items.map((item) => {
                        return deleteObject(item);
                    });
                    await Promise.all(promises);
                });

            const docRef = doc(db, `scannedItems/${barcode}`);
            await deleteDoc(docRef);

            setIsLoading(false);
            navigate(-1);
            setAlertMessage("Deleted successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAlertMessage("Error deleting! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }
    }

    const setVariables = data => {
        setName(data["name"] ?? "");
        setDescription(data["description"] ?? "");
        setIngredients(data["ingredients"] ?? []);
        setHalalStatus(data["halalStatus"] ?? "not_halal");
        setPublicationStatus(data["publicationStatus"] ?? "pending");
        setItemImagePath(data["itemImagePath"] ?? "");
        setIngredientsImagePath(data["ingredientsImagePath"] ?? "");
        setDateAdded(data["dateAdded"] ?? new Date());
        setLastUpdate(data["lastUpdate"] ?? new Date());
        setUserId(data["userId"] ?? "");
        setUserName(data["userName"] ?? "");
    }

    return (
        <Scaffold
            isLoading={isLoading}
            isError={isError}
        >
            <HeaderButtons
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                showDelete={!isEditing}
                onDelete={deleteScannedItem}
                deleteMessage='This will permanently delete the item. If you want to
                        hide it simply change its publication status to
                        "rejected". Click Confirm to delete.'
                data={scannedItem}
                updateData={updateScannedItem}
                setVariables={setVariables}
            />

            <ScannedItemHeader
                isEditing={isEditing}
                halalStatus={halalStatus}
                setHalalStatus={setHalalStatus}
                name={name}
                setName={setName}
                ingredients={ingredients}
                status={publicationStatus}
                setStatus={setPublicationStatus}
                description={description}
                setDescription={setDescription}
            />

            <Ingredients
                isEditing={isEditing}
                ingredients={ingredients}
                setIngredients={setIngredients}
            />

            <ItemInfo
                barcode={barcode}
                dateAdded={dateAdded}
                lastUpdate={lastUpdate}
                userId={userId}
                userName={userName}
            />

            <ImageSection
                barcode={barcode}
                setIsLoading={setIsLoading}
                itemImagePath={itemImagePath}
                setItemImagePath={setItemImagePath}
                ingredientsImagePath={ingredientsImagePath}
                setIngredientsImagePath={setIngredientsImagePath}
            />
        </Scaffold>
    )
}
