import {
    IconButton, ButtonGroup, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from "@mui/icons-material";
import { useState } from 'react';

const HeaderButtons = ({ isEditing, setIsEditing, data, updateData,
    setVariables, showDelete, onDelete, deleteMessage = "Are you sure you want to delete this.",
}) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="flex flex-row justify-between mr-3">
            <IconButton onClick={() => {
                navigate(-1);
            }}>
                <ArrowBack />
            </IconButton>
            {isEditing ?
                <ButtonGroup>
                    <Button variant="contained"
                        onClick={() => {
                            setIsEditing(!isEditing);
                            updateData();
                        }}
                        color="primary"
                        style={{ height: "40px", width: "120px" }}
                    >Save
                    </Button>
                    <Button variant="outlined"
                        onClick={() => {
                            setVariables(data)
                            setIsEditing(!isEditing)
                        }}
                        color="primary"
                        style={{ height: "40px", width: "120px" }}
                    >Cancel
                    </Button>
                </ButtonGroup>
                :
                <div className="space-x-1">
                    {
                        showDelete &&
                        <Button variant="contained"
                            onClick={handleClickOpen}
                            color="error"
                            style={{ height: "40px", width: "120px" }}
                        >
                            Delete
                        </Button>
                    }
                    <Button variant="contained"
                        onClick={() => setIsEditing(!isEditing)}
                        color="primary"
                        style={{ height: "40px", width: "120px" }}
                    >
                        Edit
                    </Button>
                </div>
            }


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Item?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {deleteMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => {
                        handleClose();
                        onDelete();
                    }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default HeaderButtons