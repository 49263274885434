import EditableTextField from '../EditableTextField';

import { Grid } from "@mui/material";

export default function CustomerDetail({ isEditing, order }) {
    return (
        <div className="card">
            <h3 className='text-xl font-bold mb-3'>Customer Detail</h3>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Id"
                        value={order.customerId}
                        isEditing={isEditing}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Name"
                        value={order.customerName}
                        isEditing={isEditing}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Phone"
                        value={order.customerPhone}
                        isEditing={isEditing}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditableTextField
                        label="Address"
                        value={order.customerAddress}
                        isEditing={isEditing}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
