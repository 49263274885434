import {
    MenuItem, Select, Alert, Chip, ToggleButtonGroup, ToggleButton,
    TextField
} from "@mui/material";
import EditableTextField from "../EditableTextField";
import { getStatusColor } from "../../values/helper_functions";

export default function ScannedItemHeader({ isEditing,
    halalStatus, setHalalStatus, name, setName,
    ingredients, status, setStatus, description, setDescription
}) {

    const handleChange = (event, newStatus) => {
        setHalalStatus(newStatus);
    };

    const getStatus = () => {
        if ((ingredients ?? []).length > 0) {
            if ((ingredients.map((e) => e.status).includes("not_halal"))) {
                return "not_halal"
            } else if ((ingredients.map((e) => e.status).includes("doubtful"))) {
                return "doubtful"
            } else {
                return "halal";
            }
        } else {
            return halalStatus;
        }
    }

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    function getAlertType(status) {
        if (status === "pending") {
            return "info";
        } else if (status === "published") {
            return "success";
        } else {
            return "error";
        }
    }
    return (
        <div className="card">
            <div className="flex flex-col md:flex-row items-center mb-3">
                {
                    isEditing ?
                        <EditableTextField
                            label="Name"
                            value={name}
                            setValue={setName}
                            isEditing={isEditing}
                            className="w-auto"
                        />
                        :
                        <h1 className="text-xl font-bold">
                            {name === "" ? "Unknown" : name}
                        </h1>
                }
                {
                    isEditing ?
                        <ToggleButtonGroup
                            exclusive
                            value={getStatus()}
                            onChange={handleChange}
                            style={{
                                height: "40px",
                                marginBottom: "10px",
                                marginLeft: "10px",
                                backgroundColor: "#E7E7E7FF",
                            }}
                        >
                            {
                                ["doubtful", "halal", "not_halal"].map((e) => {
                                    return <ToggleButton
                                        key={e}
                                        disabled={(ingredients ?? []).length > 0}
                                        value={e}
                                        style={{
                                            width: "120px",
                                            color: `${getStatus() === e ? "white" : "black"}`,
                                            fontWeight: `${getStatus() === e ? "bold" : "normal"}`,
                                            backgroundColor: `${getStatus() === e ? getStatusColor(getStatus()) : "inherit"}`,
                                        }}
                                    >
                                        {e.replace("_", " ").toUpperCase()}
                                    </ToggleButton>
                                })
                            }
                        </ToggleButtonGroup>
                        : <Chip
                            label={<h3>{getStatus().replace("_", " ").toUpperCase()}</h3>}
                            style={{
                                backgroundColor: getStatusColor(getStatus()),
                                color: "white",
                                fontSize: "14px",
                                marginLeft: "10px",
                            }}
                        />
                }
            </div>
            <Alert className="mb-4" severity={getAlertType(status)}>
                <span>Item publication status:</span>
                {isEditing ?
                    <Select
                        value={status}
                        onChange={handleStatusChange}
                        style={{ marginLeft: "5px" }}
                    >
                        {["pending", "published"].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    :
                    <strong> {status.toUpperCase()}</strong>
                }
            </Alert>
            <TextField
                label="Description"
                variant="outlined"
                size="small"
                fullWidth
                multiline
                minRows={4}
                value={description ?? ""}
                disabled={!isEditing}
                onChange={event => setDescription(event.target.value)}
            />
        </div>
    )
}
