import UserHeader from "../components/Admin/UserHeader";
import HeaderButtons from "../components/HeaderButtons";
import CustomTable from "../components/CustomTable";
import Scaffold from "../components/Scaffold";

import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import { getStatusColor } from '../values/helper_functions';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { db } from "../firebase-config";

const columns = [
  {
    title: "Id",
    field: "id",
  },
  {
    title: "Name",
    field: "name",
  },
  {
    title: "Address",
    field: "address",
  },
  {
    title: "Rating",
    field: "rating",
  },
  {
    title: "Orders",
    field: "orders",
  },
  {
    title: "Status",
    field: "status",
  },
];

const useStyles = makeStyles((theme) => ({
  status: {
    fontSize: "0.90rem",
    color: "white",
    backgroundColor: "gray",
    borderRadius: "8px",
    padding: "5px 13px",
    display: "inline-block"
  }
}));

export default function AdminDetail() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isError, setIsError] = useState(false);

  const [user, setUser] = useState();
  const [restaurants, setRestaurants] = useState([]);
  const [butchers, setButchers] = useState([]);
  // const [stores, setStores] = useState([]);

  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [photoUrl, setPhotoUrl] = useState([]);

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);

      try {
        const docRef = doc(db, `admins/${id}`);
        const docData = await getDoc(docRef);
        const data = docData.data();
        setUser(data);
        setVariables(data);

        const restaurantsCollectionRef = collection(db, "restaurant");
        const restaurantsData = await getDocs(restaurantsCollectionRef);
        setRestaurants(
          restaurantsData.docs.map((doc) => ({
            id: doc.id, ...doc.data()
          })).filter((doc) => {
            return (data['restaurantIdList'] ?? []).includes(doc.id);
          })
        );

        const butchersCollectionRef = collection(db, "butcher");
        const butchersData = await getDocs(butchersCollectionRef);
        setButchers(
          butchersData.docs.map((doc) => ({
            id: doc.id, ...doc.data()
          })).filter((doc) => {
            return (data['butcherIdList'] ?? []).includes(doc.id);
          })
        );

        document.title = `${data['name']}`;
      }
      catch (e) {
        console.log(e);
        setIsError(true);
      }
      finally {
        setIsLoading(false);
      }
    }

    getUser();
  }, [id]);

  const setVariables = data => {
    setUserId(data["id"]);
    setName(data["name"]);
    setIsVerified(data["isVerified"]);
    setEmail(data["email"]);
    setPhoneNumber(data["phoneNumber"]);
    setPhotoUrl(data["photoUrl"]);
  }

  return <Scaffold
    isError={isError}
    isLoading={isLoading}
  >
    <HeaderButtons
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      data={user}
      updateData={() => { }}
      setVariables={setVariables}
    />

    {/* <div className="flex flex-col lg:flex-row">
      <div className="flex-auto lg:w-96 2xl:w-400"> */}
    <UserHeader
      user={user}
      name={name}
      email={email}
      userId={userId}
      photoUrl={photoUrl}
      phoneNumber={phoneNumber}
      setVariables={setVariables}
      setIsEditing={setIsEditing}
      isVerifed={isVerified}
      isEditing={isEditing}
    />

    <CustomTable
      title="Restaurants"
      columns={columns}
      data={restaurants}
      onClick={(row) => navigate("/shops/restaurant/shop_detail/" + row.id)}
      styledCell={
        (row, cell) => <span
          className={classes[cell.field]}
          style={cell.field === "status" ? {
            backgroundColor: getStatusColor(row[cell.field])
          } : {}}
        >
          {row[cell.field] ?? "NA"}
        </span>
      }
    />
    <CustomTable
      title="Butchers"
      columns={columns}
      data={butchers}
      onClick={(row) => navigate("/shops/butcher/shop_detail/" + row.id)}
      styledCell={
        (row, cell) => <span
          className={classes[cell.field]}
          style={cell.field === "status" ? {
            backgroundColor: getStatusColor(row[cell.field])
          } : {}}
        >
          {row[cell.field] ?? "NA"}
        </span>
      }
    />
    {/* </div>
      <div className="flex-auto lg:w-32 2xl:w-8">

      </div>
    </div> */}
  </Scaffold>

}
