import React from 'react';
import ErrorPage from '../pages/ErrorPage';

import { LinearProgress, Snackbar, Alert } from '@mui/material';
import { useStateContext } from "../contexts/ContextProvider";
import { motion } from 'framer-motion';

export default function Scaffold({
    className, isError = false, isLoading = false, children
}) {
    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child);
        }
        return child;
    });

    const getPage = () => {
        if (isError) {
            return <ErrorPage />;
        }
        else if (isLoading) {
            return <LinearProgress />;
        }
        else {
            try {
                return <div>{childrenWithProps}</div>;
            } catch (e) {
                console.log(e);
                return <ErrorPage />;
            }
        }
    }

    return (
        <motion.div
            className={className}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
                paddingTop: "10px",
                overflowX: "scroll"
            }}
        >
            {getPage()}
            <SnackbarAlert />

        </motion.div>
    );
}

const SnackbarAlert = () => {
    const { openAlert, setOpenAlert, alertMessage, alertType } = useStateContext();

    return (
        <Snackbar
            open={openAlert}
            autoHideDuration={3000}
            onClose={() => setOpenAlert(false)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
            <Alert
                onClose={() => setOpenAlert(false)}
                severity={alertType}
            >
                <span className='font-bold'>{alertMessage}</span>
            </Alert>
        </Snackbar>
    )
}