import { useStateContext } from "../contexts/ContextProvider";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useParams, useNavigate } from 'react-router-dom';
import { db } from "../firebase-config";
import { useState, useEffect } from 'react';

import OrdersSideList from "../components/Shop/OrdersSideList";
import DetailsForm from "../components/Shop/DetailsForm";
import MenuSection from "../components/Shop/MenuSection";
import HeaderButtons from "../components/HeaderButtons";
import ShopHeader from "../components/Shop/ShopHeader";
import Scaffold from "../components/Scaffold";


export default function ShopDetail() {
    const { setOpenAlert, setAlertMessage, setAlertType } = useStateContext();
    const navigate = useNavigate();
    const { id, collectionName } = useParams();

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const [shop, setShop] = useState();

    const [address, setAddress] = useState("");
    const [closingTime, setClosingTime] = useState("");
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [deliveryModes, setDeliveryModes] = useState([]);
    const [deliveryTime, setDeliveryTime] = useState(0);
    const [freeDeliveryAt, setFreeDeliveryAt] = useState(0);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [openingTime, setOpeningTime] = useState("");
    const [orders, setOrders] = useState(0);
    const [pickupTime, setPickupTime] = useState(0);
    const [rating, setRating] = useState(0);
    const [shopType, setShopType] = useState("");
    const [totalRatings, setTotalRatings] = useState(0);
    const [status, setStatus] = useState("");


    useEffect(() => {
        const getRestaurant = async () => {
            setIsLoading(true);

            try {
                const docRef = doc(db, `${collectionName}/${id}`);
                const docData = await getDoc(docRef);
                const data = docData.data();
                setShop(data);
                setVariables(data);

                document.title = `${data['name']}`;
            } catch (e) {
                console.log(e);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getRestaurant();
    }, [id]);

    const setVariables = data => {
        setAddress(data['address']);
        setClosingTime(data['closingTime']);
        setDeliveryFee(data['deliveryFee']);
        setDeliveryTime(data['deliveryTime']);
        setDeliveryModes(data['deliveryModes']);
        setFreeDeliveryAt(data['freeDeliveryAt']);
        setLat(data['lat']);
        setLng(data['lng']);
        setOpeningTime(data['openingTime']);
        setOrders(data['orders']);
        setPickupTime(data['pickupTime']);
        setRating(data['rating']);
        setShopType(data['shopType']);
        setTotalRatings(data['totalRatings']);
        setStatus(data['status']);
    }

    const updateShop = async () => {
        setIsLoading(true);

        try {
            const docRef = doc(db, `${collectionName}/${id}`);
            await updateDoc(
                docRef,
                {
                    'address': address ?? null,
                    'closingTime': closingTime ?? null,
                    'deliveryFee': deliveryFee ?? null,
                    'deliveryTime': deliveryTime ?? null,
                    'deliveryModes': deliveryModes ?? null,
                    'freeDeliveryAt': freeDeliveryAt ?? null,
                    'lat': lat ?? null,
                    'lng': lng ?? null,
                    'openingTime': openingTime ?? null,
                    'orders': orders ?? null,
                    'pickupTime': pickupTime ?? null,
                    'rating': rating ?? null,
                    'shopType': shopType ?? null,
                    'totalRatings': totalRatings ?? null,
                    'status': status ?? null,
                }
            );
            setAlertMessage("Updated successfully");
            setAlertType("success");
            setOpenAlert(true);
        } catch (e) {
            console.log(e);
            setAlertMessage("Failed to update! Try again.");
            setAlertType("error");
            setOpenAlert(true);
        }

        setIsLoading(false);
    }

    return <Scaffold
        isError={isError}
        isLoading={isLoading}
    >
        <HeaderButtons
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            data={shop}
            updateData={updateShop}
            setVariables={setVariables}
        />

        <div className="flex flex-col lg:flex-row">
            <div className="flex-auto lg:w-96 2xl:w-400">

                <ShopHeader
                    shop={shop}
                    rating={rating}
                    totalRatings={totalRatings}
                    orders={orders}
                    isEditing={isEditing}
                    status={status}
                    setStatus={setStatus}
                />

                {
                    shop && shop["imagePath"] && <div className="card">
                        <img
                            loading="lazy"
                            className="w-full"
                            src={shop["imagePath"]}
                            alt="Restaurant"
                        />
                    </div>
                }

                <DetailsForm
                    isEditing={isEditing}
                    address={address}
                    setAddress={setAddress}
                    openingTime={openingTime}
                    setOpeningTime={setOpeningTime}
                    closingTime={closingTime}
                    setClosingTime={setClosingTime}
                    deliveryModes={deliveryModes}
                    setDeliveryModes={setDeliveryModes}
                    deliveryFee={deliveryFee}
                    setDeliveryFee={setDeliveryFee}
                    freeDeliveryAt={freeDeliveryAt}
                    setFreeDeliveryAt={setFreeDeliveryAt}
                    deliveryTime={deliveryTime}
                    setDeliveryTime={setDeliveryTime}
                    pickupTime={pickupTime}
                    setPickupTime={setPickupTime}
                />

                <MenuSection collectionName={collectionName} shopId={id} />
            </div>

            <div className="flex-auto lg:w-32 2xl:w-8">
                <OrdersSideList
                    collectionName={collectionName}
                    docId={id}
                    isHistory={false}
                    onClick={(oid) => navigate(`order_detail/0/${oid}`)}
                />
                <OrdersSideList
                    collectionName={collectionName}
                    docId={id}
                    isHistory={true}
                    onClick={(oid) => navigate(`order_detail/1/${oid}`)}
                />
            </div>
        </div>

    </Scaffold>
}
