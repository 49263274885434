import "./app.css"

import ErrorPage from "./pages/ErrorPage";
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import AnimatedRoutes from "./AminatedRoutes";
import React from 'react'

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useStateContext } from "./contexts/ContextProvider";
import Footer from "./components/Footer";


const App = () => {
  const { activeMenu } = useStateContext();
  try {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="flex relative">
          <div className="z-40">
            {
              activeMenu ? (
                <div className="w-72 fixed sidebar bg-white ">
                  <Sidebar />
                </div>
              ) : (
                <div className="w-0">
                  <Sidebar />
                </div>
              )
            }
          </div>
          <div
            className={
              activeMenu
                ? 'bg-main-bg min-h-screen md:ml-72 w-full z-0'
                : 'bg-main-bg min-h-screen flex-2 w-full z-0'
            }
          >
            <div className="flex flex-col h-full" style={{ backgroundColor: "whitesmoke" }}>
              <div className="h-14 fixed bg-main-bg navbar w-full sm:static">
                <Navbar />
              </div>
              <div className="flex-auto mt-16 sm:mt-0">
                <AnimatedRoutes />
              </div>
              <div className="h-12 w-full md:static">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </LocalizationProvider>
    );
  } catch (e) {
    console.log(e);
    return <ErrorPage />;
  }
}

export default App