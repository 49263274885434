import {
    DashboardOutlined,
    LocalDiningOutlined,
    ScannerOutlined,
    PersonOutlined,
    VerifiedUserOutlined,
    LocalOfferOutlined,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { ReactComponent as Butchers } from "../assets/meat.svg";
import { useStateContext } from "../contexts/ContextProvider";
import { Link, NavLink } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { SiShopware } from 'react-icons/si';

export default function Sidebar() {
    const { activeMenu, setActiveMenu, screenSize } = useStateContext();

    const links = [
        {
            title: 'Dashboard',
            links: [
                {
                    name: "Dashboard",
                    path: "/dashboard",
                    icon: <DashboardOutlined />,
                },
            ],
        },
        {
            title: "Shops",
            links: [
                {
                    name: "Restaurants",
                    path: "/shops/restaurant",
                    icon: <LocalDiningOutlined />,
                },
                {
                    name: "Butchers",
                    path: "/shops/butcher",
                    icon: <SvgIcon className='sidebar__icon' >
                        <Butchers />
                    </SvgIcon>,
                }
            ]
        },
        {
            title: "Users",
            links: [

                {
                    name: "Users",
                    path: "/users",
                    icon: <PersonOutlined />,
                },
                {
                    name: "Admins",
                    path: "/admins",
                    icon: <VerifiedUserOutlined />,
                }
            ]
        },
        {
            title: "Others",
            links: [

                {
                    name: "Scanned Items",
                    path: "scanned_items",
                    icon: <ScannerOutlined />,
                },
                {
                    name: "Promotions",
                    path: "promotions",
                    icon: <LocalOfferOutlined />,
                }
            ]
        }
    ];

    const currentColor = "#42a5f5FF";
    const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
    const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';

    const handleCloseSideBar = () => {
        if (activeMenu !== undefined && screenSize <= 900) {
            setActiveMenu(false);
        }
    };

    return (
        <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
            {activeMenu && (
                <>
                    <div className="flex justify-between items-center">
                        <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
                            <SiShopware /> <span>Halal Finder</span>
                        </Link>

                        <button
                            type="button"
                            onClick={() => setActiveMenu(!activeMenu)}
                            style={{ color: currentColor }}
                            className="text-xl rounded-full p-3 hover:bg-light-gray  mt-4 block md:hidden"
                        >
                            <MdOutlineCancel />
                        </button>
                    </div>

                    <div className="mt-10 ">
                        {links.map((item) => (
                            <div key={item.title}>
                                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                                    {item.title}
                                </p>
                                {item.links.map((link) => (
                                    <NavLink
                                        to={link.path}
                                        key={link.path}
                                        onClick={handleCloseSideBar}
                                        style={({ isActive }) => ({
                                            backgroundColor: isActive ? currentColor : '',
                                        })}
                                        className={({ isActive }) => (isActive ? activeLink : normalLink)}
                                    >
                                        {link.icon}
                                        <span>{link.name}</span>
                                    </NavLink>
                                ))}
                            </div>
                        ))}
                    </div>
                </>
            )
            }
        </div>
    )
}
