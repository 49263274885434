import OrdersSideList from "../components/Shop/OrdersSideList";
import HeaderButtons from "../components/HeaderButtons";
import UserHeader from "../components/User/UserHeader";
import UserInfo from "../components/User/UserInfo";
import Scaffold from "../components/Scaffold";

import { getDoc, doc } from "firebase/firestore";
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { db } from "../firebase-config";


export default function UserDetail() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isError, setIsError] = useState(false);

    const [user, setUser] = useState();

    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [addresses, setAddresses] = useState([]);
    const [photoUrl, setPhotoUrl] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState("");

    useEffect(() => {
        const getUser = async () => {
            setIsLoading(true);

            try {
                const docRef = doc(db, `users/${id}`);
                const docData = await getDoc(docRef);
                const data = docData.data();
                setUser(data);
                setVariables(data);

                document.title = `${data['name']}`;
            } catch (e) {
                console.log(e);
                setIsError(true);
            }
            finally {
                setIsLoading(false);
            }
        }

        getUser();
    }, [id]);

    const setVariables = data => {
        setUserId(data["id"]);
        setName(data["name"]);
        setIsVerified(data["isVerified"]);
        setEmail(data["email"]);
        setPhoneNumber(data["phoneNumber"]);
        setAddresses(data["addresses"]);
        setPhotoUrl(data["photoUrl"]);
        setSelectedAddress(data["selectedAddress"]);
    }

    return (
        <Scaffold
            isLoading={isLoading}
            isError={isError}
        >
            <HeaderButtons
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                data={user}
                updateData={() => { }}
                setVariables={setVariables}
            />

            <div className="flex flex-col lg:flex-row">
                <div className="flex-auto lg:w-96 2xl:w-400">
                    <UserHeader
                        user={user}
                        setVariables={setVariables}
                        photoUrl={photoUrl}
                        name={name}
                        userId={userId}
                        email={email}
                        isVerifed={isVerified}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                    <UserInfo
                        isEditing={isEditing}
                        phoneNumber={phoneNumber}
                        addresses={addresses}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                    />
                </div>
                <div className="flex-auto lg:w-32 2xl:w-8">
                    <OrdersSideList
                        collectionName="users"
                        docId={userId}
                        isHistory={false}
                        onClick={(oid) => navigate(`order_detail/users/0/${oid}`)}
                    />
                    <OrdersSideList
                        collectionName="users"
                        docId={userId}
                        isHistory={true}
                        onClick={(oid) => navigate(`order_detail/1/${oid}`)}
                    />
                </div>
            </div>
        </Scaffold>
    )

}
